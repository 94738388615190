/**
 * According to the specs some data should be adjusted
 */
const trimData = data => {
  const {
    customer: { postalCode }
  } = data

  return {
    ...data,
    customer: {
      ...data.customer,
      postalCode: postalCode.substr(0, 4)
    }
  }
}

export default trimData
