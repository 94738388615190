import { isValid, validate } from './utils/validate'
import { isEnvProduce } from './utils/envUtils'
import pushData from './utils/pushData'
import trimData from './utils/trimData'
import logger from './services/logger'

import { DATA_KEY } from './consts'

const main = async () => {
  if (typeof window === 'undefined' || !window[DATA_KEY]) {
    logger.debug('No data object available')

    return
  }

  const data = window[DATA_KEY]

  if (isValid(data)) {
    if (await pushData(trimData(data))) {
      logger.debug('Data successfully sent')
    } else {
      logger.error('Cannot send data')
    }
  } else if (!isEnvProduce()) {
    logger.error('Invalid data:')
    validate(data).forEach(error => logger.error(error.message))
  }
}

main()
