// eslint-disable-next-line no-restricted-imports
import { isEnvTest } from '../utils/envUtils'

const dummy = () => undefined

const loggerFactory = () => {
  // To not let unexpected output be printed during the test
  if (isEnvTest()) {
    return {
      log: dummy,
      error: dummy,
      debug: dummy,
      warn: dummy
    }
  }

  // FIXME: Temporary return `console` for logging
  return console
}

export default loggerFactory()
